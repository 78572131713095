import PropsStateToggle from "../../../../content/blog/react-pass-props-to-component/components/PropsStateToggle.js";
import PropsStateText from "../../../../content/blog/react-pass-props-to-component/components/PropsStateText.js";
import PropsCounter from "../../../../content/blog/react-pass-props-to-component/components/PropsCounter.js";
import PropsRenderProps from "../../../../content/blog/react-pass-props-to-component/components/PropsRenderProps.js";
import * as React from 'react';
export default {
  PropsStateToggle,
  PropsStateText,
  PropsCounter,
  PropsRenderProps,
  React
};
import Count from "../../../../content/blog/react-hook-detect-click-outside-component/components/Count.js";
import WithHook from "../../../../content/blog/react-hook-detect-click-outside-component/components/WithHook.js";
import WithHookBug from "../../../../content/blog/react-hook-detect-click-outside-component/components/WithHookBug.js";
import WithoutHookBug from "../../../../content/blog/react-hook-detect-click-outside-component/components/WithoutHookBug.js";
import * as React from 'react';
export default {
  Count,
  WithHook,
  WithHookBug,
  WithoutHookBug,
  React
};